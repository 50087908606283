// material-ui
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// types
import Navbar from '../components/navBar';
import Tab from '../components/admin/tab';

export default function Admin() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar />
      <main className={classes.content}>
        <Tab></Tab>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '80vh',
      minHeight: '600px',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        height: '0',
        minHeight: '600px'
      }
    },
    overlay: {
      position: 'absolute',
      width: '100%',
      height: '50%',
      zIndex: 5,
      backdropFilter: 'blur(20px)',
      overflowX: 'hidden'
    }
  })
);
