import { makeStyles, createStyles } from "@material-ui/core/styles";

// configs
import font from "../configs/font";
import colors from "../configs/colors";
import Spinner from "./Spinner";

interface ButtonProps {
  text: string;
  className?: any;
  onClick: () => void;
  loading?: boolean;
  invert?: boolean;
  disabled?: boolean;
}

export default function Button(props: ButtonProps) {
  const classes = useStyles();
  return (
    <button
      className={`${props.invert ? classes.invertedButton : classes.button} ${
        props.className
      }`}
      onClick={props.onClick}
      disabled={props.loading || props.disabled}
    >
      {props.loading && <Spinner />}
      {props.text}
    </button>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      color: "#fff",
      backgroundColor: colors.btnPrimary,
      fontFamily: font.primary,
      fontSize: "18px",
      letterSpacing: "0.15em",
      lineHeight: "32px",
      textTransform: "uppercase",
      border: "none",
      padding: "5px 20px",
      borderRadius: 50,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:disabled": {
        cursor: "not-allowed",
      },
      "&:hover": {
        background: colors.btnPrimary,
      },
    },
    invertedButton: {
      backgroundColor: colors.lightGrey,
      color: colors.textDarkgrey,
      border: `1px solid ${colors.borderPrimary}`,
      fontFamily: font.primary,
      fontSize: "14.6px",
      letterSpacing: "0.15em",
      lineHeight: "32px",
      textTransform: "uppercase",
      padding: "0px 10px",
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:disabled": {
        cursor: "not-allowed",
      },
    },
  })
);
