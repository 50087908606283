import moment from "moment";

// convert string to date
export function stringToDate(dateString: string): Date {
  return new Date(dateString);
}

// convert date to unix timestamp
export function toUnixTimestamp(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

// convert unix timestamp to date
export function fromUnixTimestamp(unixTimestamp: number) {
  return new Date(unixTimestamp * 1000);
}

// moment fromNow implemenation
export function fromNow(date: number) {
  return moment(fromUnixTimestamp(date)).fromNow(true);
}
