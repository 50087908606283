import airtable from 'airtable'

const getAirtableInstance = () => {
  return new airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API_KEY }).base(
    process.env.REACT_APP_AIRTABLE_BASE_ID as string
  )
}

export const chumbiTermsAgreedBase = async (address: string) => {
  const base = getAirtableInstance()
  await base('Private vesting investors').create([{
    fields: {
      address,
      hasAgreed: 1,
    }
  }])
}

export const chumbiTermsAgreedCheck = async (address: string) => {
  const base = getAirtableInstance()
  const records = await base('Private vesting investors').select({
    filterByFormula: `{address} = '${address}'`,
  }).firstPage()
  return records.length > 0
}
