import {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback
} from 'react';

// hooks
import { useMetamask } from './Metamask';

// libraries
import Container from 'typedi';

// helpers
import { getWeb3 } from '../utilities/web3';
import { ContractsInstance } from '../utilities/getContract';

// types
import {
  ChumbiVestingAddress,
  ChumbiVestingABI,
  ChumbiABI,
  ChumbiAddress
} from '../configs/contract';
import { ChumbiVesting } from '../types/NIFTYVesting';
import { ChumbiToken } from '../types/NIFTYToken';

interface ContractContext {
  ChumbiVesting: ChumbiVesting | undefined;
  ChumbiToken: ChumbiToken | undefined;
}

interface ContractProviderProps {
  children: JSX.Element;
}

const Contract = createContext<ContractContext>({
  ChumbiVesting: undefined,
  ChumbiToken: undefined
});

const ContractProvider = (props: ContractProviderProps) => {
  const metamask = useMetamask();
  const [ChumbiContract, setChumbiContract] = useState<ChumbiVesting>();
  const [ChumbiTokenContract, setChumbiTokenContract] = useState<ChumbiToken>();

  const load = useCallback(async () => {
    const contracts = Container.get(ContractsInstance);
    if (metamask.user.primaryAccount) {
      const web3 = await getWeb3();
      const ChumbiVestingContract = new web3.eth.Contract(
        ChumbiVestingABI as any,
        ChumbiVestingAddress
      ) as unknown as ChumbiVesting;
      const ChumbiTokenContract = new web3.eth.Contract(
        ChumbiABI as any,
        ChumbiAddress
      ) as unknown as ChumbiToken;
      contracts.ChumbiVesting = ChumbiVestingContract;
      contracts.ChumbiToken = ChumbiTokenContract;
      setChumbiContract(contracts.ChumbiVesting);
      setChumbiTokenContract(contracts.ChumbiToken);
    }
  }, [metamask.user.primaryAccount]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Contract.Provider
      value={{
        ChumbiVesting: ChumbiContract,
        ChumbiToken: ChumbiTokenContract
      }}
    >
      {props.children}
    </Contract.Provider>
  );
};

export const useContract = () => {
  return useContext(Contract);
};

export default ContractProvider;
