import ChumbiVestingABI from "contracts/ChumbiVesting.json";
import ChumbiTokenABI from "contracts/ChumbiToken.json";

import { ChumbiVesting } from "types/ChumbiVesting";
import { ChumbiToken } from "types/ChumbiToken";
import { AllowedChainConfig, ContractConfig } from "types/config";

export const allowedChains: AllowedChainConfig[] = [
  // {
  // 	id: 97,
  // 	name: 'BSC Testnet',
  // 	rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
  // },
  {
    id: 56,
    name: "BSC Mainnet",
    rpcUrl: "https://bsc-dataseed.binance.org/",
  },
];

export const ContractAddress = {
  Vesting_General: "0x389D1e034E72B46e062133Ac30ca9FeeABb031Ad",
  Vesting_Seed: "0x1A0a045518983F1fcb7a67EAeDD0d0015D8c687f",
  Vesting_New: "0xA124CD5B0Db95D90C2BA8f799FecaA6A13738583",
  Token: "0x5492Ef6aEebA1A3896357359eF039a8B11621b45",
};

export const contracts: ContractConfig[] = [
  {
    name: "Vesting_General",
    abi: ChumbiVestingABI.abi,
    address: ContractAddress.Vesting_General,
  },
  {
    name: "Vesting_Seed",
    abi: ChumbiVestingABI.abi,
    address: ContractAddress.Vesting_Seed,
  },
  {
    name: "Vesting_New",
    abi: ChumbiVestingABI.abi,
    address: ContractAddress.Vesting_New,
  },
  {
    name: "Token",
    abi: ChumbiTokenABI.abi,
    address: ContractAddress.Token,
  },
];

export interface ContractInstances {
  Vesting_General: ChumbiVesting;
  Vesting_Seed: ChumbiVesting;
  Vesting_New: ChumbiVesting;
  Token: ChumbiToken;
}
