import { Switch, Route } from "react-router-dom";

// hooks
import { useMetamask } from "./contexts/Metamask";

// pages
import ClaimTokens from "./pages/ClaimTokens";
import Admin from "./pages/Admin";
import MetamaskAuth from "./pages/MetamaskAuth";
import MetamaskNotAvailable from "./pages/MetamaskNotAvailable";

export default function App() {
  const wallet = useMetamask();

  if (typeof window.ethereum === "undefined") {
    return <MetamaskNotAvailable />;
  }

  if (!wallet?.user?.accounts[0]) {
    return <MetamaskAuth />;
  }

  return (
    <Switch>
      <Route path="/admin">
        <Admin />
      </Route>
      <Route path="/">
        <ClaimTokens />
      </Route>
    </Switch>
  );
}
