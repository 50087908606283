// hooks
import { useContext, useEffect } from 'react';
import useSwitch from '../../hooks/useSwitch';
import useInput from '../../hooks/useInput';
import { useMetamask } from '../../contexts/Metamask';

// libraries
import clsx from 'clsx';
import { toast } from 'react-toastify';

// material-ui
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// components
import Button from '../Button';

// configs
import font from '../../configs/font';
import colors from '../../configs/colors';

// helpers
import { VestingContext } from './tab';
import Contracts from 'helpers/contracts';

export default function Pause() {
	const classes = useStyles();
	const metamask = useMetamask();
	const isClaimPaused = useSwitch(false);
	const isTransactionInProgress = useSwitch();
	const { selectedVesting } = useContext(VestingContext);

	useEffect(() => {
		console.log('pause');
		const fetchClaimPaused = async () => {
			const isConnectedToBSC = await metamask.checkIsBsc();
			if (!isConnectedToBSC) {
				toast.error('Please connect to the Bsc Chain');
				isTransactionInProgress.false();
				return;
			}

			const contracts = Contracts.instances;
			const VestingContract = contracts[`Vesting_${selectedVesting}`];
			try {
				const claimPaused = await VestingContract.methods.paused().call();
				isClaimPaused.set(claimPaused);
			} catch (err) {
				toast.error('Could not fetch pause status');
			}
		};
		fetchClaimPaused();
	}, [selectedVesting]);

	const pauseUnpauseClaim = async () => {
		const isConnectedToPolygon = await metamask.checkIsBsc();
		if (!isConnectedToPolygon) {
			toast.error('Please connect to the Bsc Chain');
			isTransactionInProgress.false();
			return;
		}

		const contract = Contracts.instances;
		const VestingContract = contract[`Vesting_${selectedVesting}`];
		isTransactionInProgress.true();
		try {
			if (isClaimPaused.value) {
				await VestingContract.methods
					.unpause()
					.send({ from: metamask.user.primaryAccount });
				isClaimPaused.false();
				toast.success('Claim Resume');
			} else {
				await VestingContract.methods
					.pause()
					.send({ from: metamask.user.primaryAccount });
				isClaimPaused.true();
				toast.success('Claim Paused');
			}
		} catch (err) {
			console.log(err);
			toast.error('Could not pause claim');
		}
		isTransactionInProgress.false();
	};

	return (
		<div className={classes.block}>
			<div className={classes.flexDirectionColumn}>
				<table>
					<tbody>
						<tr className={classes.inputRow} style={{ width: 500 }}>
							<td className={`${classes.label}`}>Is Claim Paused?</td>
							<td>
								<div
									className={clsx({
										[classes.isAdminButton]: true,
										[classes.green]: isClaimPaused.value,
										[classes.red]: !isClaimPaused.value,
									})}
								>
									<span className={classes.isAdminButtonText}>
										{isClaimPaused.value ? 'True' : 'False'}
									</span>
								</div>
							</td>
						</tr>
						<tr className={classes.inputRow} style={{ visibility: 'hidden' }}>
							<td className={`${classes.label}`}>Wallet Address</td>
							<td>
								<input
									className={classes.input}
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
					</tbody>
				</table>
				<Button
					className={classes.button}
					text={isClaimPaused.value ? 'Resume' : 'Pause'}
					onClick={pauseUnpauseClaim}
					loading={isTransactionInProgress.value}
				/>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		block: {
			height: '50%',
			padding: '40px 40px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			borderRadius: '16px',
		},
		image: {
			height: 60,
			marginLeft: 80,
		},
		inputRow: {
			[theme.breakpoints.down('xs')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		},
		title: {
			fontFamily: font.primary,
			fontSize: font.fontSize.l,
			color: colors.textSecondary,
			letterSpacing: '0.05em',
		},
		label: {
			fontFamily: font.primary,
			fontSize: font.fontSize.sm,
			lineHeight: '24px',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			padding: '17px 0px',
		},
		input: {
			width: '380px',
			marginLeft: '30px',
			height: 0,
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				width: '80vw',
			},
			outline: 'none',
			'&:focus': {
				backgroundColor: '#fafafa',
				borderColor: '#8262d1',
			},
		},
		isAdminButton: {
			fontFamily: font.primary,
			fontSize: '16px',
			lineHeight: '24px',
			width: '120px',
			height: '25px',
			borderRadius: 50,
			border: 'none',
			transition: 'background 250ms ease-in-out',
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.5em',
			marginLeft: '38px',
			backgroundColor: '#10C400',
			color: '#fff',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
			},
		},
		green: {
			backgroundColor: '#4bb771',
		},
		red: {
			backgroundColor: '#d33853',
		},
		isAdminButtonText: {
			marginLeft: 7,
		},
		flexDirectionColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		button: {
			marginTop: '16px',
			fontSize: font.fontSize.sm,
			transition: 'all 0.15s linear',
			'&:hover': {
				transform: 'scale(1.1)',
			},
		},
	})
);
