// hooks
import { useMetamask } from '../contexts/Metamask';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';

// configs
import colors from '../configs/colors';
import font from '../configs/font';
import { ChumbiAddress } from '../configs/contract';

// assets
import Logo from '../assets/Logo.png';
import useSwitch from '../hooks/useSwitch';
import { useEffect } from 'react';
import { allowedChains } from 'config';

export default function Navbar() {
	const classes = useStyles();
	const metamask = useMetamask();

	const connectedToBSC = useSwitch(true);

	useEffect(() => {
		const checkConnection = async () => {
			const isConnectedToBSC = await metamask.checkIsBsc();
			connectedToBSC.set(isConnectedToBSC);
		};
		checkConnection();
	}, []);

	const addTokenToWallet = async () => {
		const isConnectedToPolygon = await metamask.checkIsBsc();
		if (!isConnectedToPolygon) {
			toast.error('Please connect to the Bsc Chain');
			return;
		}

		let tokenAddress = ChumbiAddress;

		try {
			await (window as any).ethereum.request({
				method: 'wallet_watchAsset',
				params: {
					type: 'ERC20',
					options: {
						address: tokenAddress,
						symbol: 'CHMB',
						decimals: 18,
						image: 'https://chumbivalley.com/token.png',
					},
				},
			});
		} catch (error) {
			console.log(error);
		}
	};

	async function checkNetwork() {
		if (!window.ethereum) return;
		try {
			const res = await window.ethereum.request({
				method: 'wallet_switchEthereumChain',
				params: [
					{
						chainId: `0x${Number(allowedChains[0].id).toString(16)}`,
					},
				],
			});
		} catch (error: any) {
			if (error.code === 4902) {
				try {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [
							{
								chainId: `0x${Number(allowedChains[0].id).toString(16)}`,
								rpcUrl: allowedChains[0].rpcUrl,
							},
						],
					});
				} catch (addError) {
					console.error(addError);
				}
			}
		}
	}

	return (
		<nav className={classes.nav}>
			<div className={classes.logoContainer}>
				<img src={Logo} alt='Chumbi Logo' className={classes.logo} />
			</div>

			{!connectedToBSC.value && (
				<button className={classes.switchButton} onClick={checkNetwork}>
					Switch to Bsc
				</button>
			)}
			<button className={classes.button}>
				<a
					className={classes.link}
					href='https://staking.chumbivalley.com'
					rel='noreferrer'
					target='_blank'
				>
					Stake $CHMB
				</a>
			</button>
			<button className={classes.button} onClick={addTokenToWallet}>
				Add $CHMB to Metamask
			</button>

			{metamask?.user?.accounts[0] && (
				<button className={classes.button} onClick={metamask.disconnect}>
					Disconnect
				</button>
			)}

			<Typography className={classes.walletAddress}>
				{metamask?.user?.accounts[0] &&
					metamask?.user?.accounts[0].substr(0, 6) +
						'...' +
						metamask?.user?.accounts[0].substr(
							metamask?.user?.accounts[0].length - 5
						)}
			</Typography>
		</nav>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		logoContainer: {
			marginRight: 'auto',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down(1000)]: {
				margin: '0',
				marginBottom: '10px',
			},
		},
		logo: {
			height: 89,
		},

		nav: {
			width: '100vw',
			display: 'flex',
			alignItems: 'center',
			padding: '30px 40px',
			gap: '10px',
			[theme.breakpoints.down(1000)]: {
				flexDirection: 'column',
				gap: '12px',
				alignItems: 'center',
				justifyContent: 'center',
				marginRight: '0px',
				marginBottom: '40px',
			},
		},
		list: {
			display: 'flex',
			alignItems: 'center',

			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		walletAddress: {
			color: colors.white,
			border: `2px solid ${colors.bgPrimary}`,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0.4rem 1rem',

			borderRadius: '.5rem',
			marginRight: '10px',
			fontSize: '20px',
			[theme.breakpoints.down('md')]: {
				marginRight: '0px',
			},
		},

		stakeChmbButton: {
			border: 'none',
			outline: 'none',
			color: colors.textWhite,
			backgroundColor: 'grey',
			fontFamily: font.primary,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0.5rem 1rem',
			borderRadius: '.5rem',
			marginRight: '20px',
			cursor: 'pointer',
			textDecoration: 'none',
			fontSize: '20px',
			transition: 'all 0.2s linear',

			[theme.breakpoints.down(1000)]: {
				marginRight: '0px',
			},
		},
		button: {
			border: 'none',
			outline: 'none',
			color: colors.textWhite,
			backgroundColor: colors.bgPrimary,
			fontFamily: font.primary,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0.5rem 1rem',
			borderRadius: '.5rem',
			marginRight: '20px',
			cursor: 'pointer',
			textDecoration: 'none',
			fontSize: '20px',
			transition: 'all 0.2s linear',
			'&:hover': {
				transform: 'scale(1.1)',
			},
			[theme.breakpoints.down(1000)]: {
				marginRight: '0px',
			},
		},
		link: {
			border: 'none',
			outline: 'none',
			cursor: 'pointer',
			textDecoration: 'none',
			color: colors.textWhite,
		},
		switchButton: {
			color: colors.textWhite,
			backgroundColor: colors.bgPrimary,
			fontFamily: font.primary,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			padding: '0.5rem 1rem',
			borderRadius: '.5rem',
			marginRight: '20px',
			fontSize: '20px',
			border: 'none',
			outline: 'none',
			cursor: 'pointer',
			transition: 'all 0.2s linear',
			'&:hover': {
				transform: 'scale(1.1)',
			},
			[theme.breakpoints.down('md')]: {
				marginRight: '0px',
			},
		},
	})
);
