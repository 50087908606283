
// material-ui
import CssBaseline from '@material-ui/core/CssBaseline';
import { Typography as Text } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// components
import Button from '../components/Button';

// configs
import font from '../configs/font';
import colors from '../configs/colors';

// assets
import metamaskLogo from '../assets/metamask.png';

// types
import { useMetamask } from '../contexts/Metamask';

export default function MetamaskAuth() {
  const classes = useStyles();
  const { connect } = useMetamask();

  const connectWallet = () => {
    connect();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <Navbar /> */}
      <main className={classes.content}>
        {/* <div>
          <Text className={classes.title}>claim tokens</Text>
          <table>
            <tbody>
              <tr>
                <td className={classes.label}>Claimed</td>
                <td>
                  <ProgressBar
                    progress={70}
                    bgColor='#E4E4E4'
                    fillColor='#04D9D9'
                  />
                </td>
                <td>
                  <Text className={classes.desc}>
                    <span className={classes.green}>600</span> / 400 Claimable
                  </Text>
                </td>
              </tr>
              <tr>
                <td className={classes.label}>Availabilty</td>
                <td>
                  <ProgressBar
                    progress={50}
                    bgColor='#04D9D9'
                    fillColor='#022859'
                  />
                </td>
                <td>
                  <Text className={classes.desc}>
                    <span className={classes.red}>200</span> / 600 Claimed
                  </Text>
                </td>
              </tr>
              <tr>
                <td className={classes.label}>Time Left</td>
                <td colSpan={2}>
                  <Text className={classes.timeLeft}>
                    14 Days Till Maturity
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
          <Button text='Claim Chumbi' onClick={connectWallet} />
        </div> */}
        <div className={classes.overlay}></div>
        <div className={classes.overlayDetails}>
          <img src={metamaskLogo} alt='Metamask Logo' height='129px' />
          <Text className={classes.alertDetail}>
            Metamask Connection Required
          </Text>
          <Button
            className={classes.button}
            text='Connect Wallet'
            onClick={connectWallet}
          />
        </div>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%'
    },
    content: {
      display: 'flex',
      flexGrow: 1,
      padding: theme.spacing(3),
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%'
    },
    title: {
      fontFamily: font.primary,
      fontSize: '48px',
      color: colors.textSecondary,
      letterSpacing: '0.15em',
      lineHeight: '72px',
      marginBottom: '30px'
    },
    data: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '30px'
    },
    label: {
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      letterSpacing: '0.5em'
    },
    desc: {
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      letterSpacing: '0.5em',
      marginLeft: '20px'
    },
    green: {
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      letterSpacing: '0.5em',
      marginLeft: '20px',
      color: '#10C400'
    },
    red: {
      fontSize: '24px',
      lineHeight: '36px',
      textTransform: 'uppercase',
      letterSpacing: '0.5em',
      marginLeft: '20px',
      color: '#FF0000'
    },
    timeLeft: {
      color: colors.textSecondary,
      fontFamily: font.primary,
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '36px',
      letterSpacing: '0.5em',
      textTransform: 'uppercase',
      marginLeft: 20
    },
    overlay: {
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100vh',
      zIndex: 5,
      backdropFilter: 'blur(50px)',
      overflowX: 'hidden'
    },
    overlayDetails: {
      height: '100%',
      zIndex: 10,
      position: 'absolute',
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center'
    },
    alertDetail: {
      color: colors.textPrimary,
      fontFamily: font.primary,
      fontSize: '30px',
      lineHeight: '40px',
      textTransform: 'uppercase',
      letterSpacing: '0.15em',
      marginBottom: '30px',
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
          fontSize: '42px',
          lineHeight: '60px'
      },
    },
    button: {
      transition: 'all 0.15s linear',
      '&:hover': {
        transform: 'scale(1.1)'
      }
    }
  })
);
