// hooks
import { useState, useEffect, ChangeEvent } from "react";
import { useMetamask } from "contexts/Metamask";
import useSwitch from "hooks/useSwitch";

// libraries
import { toast } from "react-toastify";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography as Text } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

// components
import ProgressBar from "components/ProgressBar";
import Button from "components/Button";

// configs
import font from "configs/font";
import colors from "configs/colors";

// helpers
import { fromNow } from "utilities/utils";
import Navbar from "components/navBar";
import Contracts from "helpers/contracts";
import TermsModal from "../components/TermsModal";
import {chumbiTermsAgreedCheck} from "../configs/airtable";

export default function ClaimTokens() {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [isAlreadyAgreed, setIsAlreadyAgreed] = useState(false);

  const classes = useStyles();
  const metamask = useMetamask();
  const isConnectedToBSC = useSwitch(false);
  // const isAvailabilty = useSwitch(true);
  // const isClaimAvailable = useSwitch(false);
  const [selectedVesting, setSelectedVesting] = useState<
    "General" | "Seed" | "New"
  >("General");

  const [claim, setClaim] = useState<any>({
    totalAmount: 0,
    amountClaimed: 0,
    unlockAmount: 0,
    vestAmount: 0,
    claimableAmount: 0,
    endTime: null,
    paused: false,
  });

  const isTransactionInProgress = useSwitch();

  const changeVesting = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedVesting(e.target.value as "General" | "Seed" | "New");
  };

  const fetchClaim = async () => {
    const contracts = Contracts.instances;
    const VestingContract = contracts[`Vesting_${selectedVesting}`];
    if (!VestingContract?.methods) {
      return;
    }
    /*  console.log(totalAmount - amountClaimed == 0)
    if (totalAmount - amountClaimed == 0) return; */
    const isConnectedToBSC = await metamask.checkIsBsc();
    if (!isConnectedToBSC) {
      toast.error("Please connect to the Bsc Chain");
      isTransactionInProgress.false();
      return;
    }

    try {
      const account = metamask.user.primaryAccount;

      const claim = await VestingContract.methods
        .getClaim(account)
        .call({ from: account });

      const claimableAmount = await VestingContract.methods
        .claimableAmount(account)
        .call({ from: account });

      const paused = await VestingContract.methods.paused().call();

      setClaim({
        ...claim,
        paused,
        claimableAmount,
      });


      // if (!isClaimAvailable.value) isClaimAvailable.set(true);
    } catch (err) {
      console.log(err);
      toast.error("Could not fetch claim");
    }
  };

  const setNetwork = async () => {
    const isConnected = await metamask.checkIsBsc();
    if (isConnected) {
      isConnectedToBSC.set(true);
    } else {
      toast.error("Please connect to the Bsc Chain");
    }
  };

  useEffect(() => {
    if (!isConnectedToBSC.value) return;
    // if (!isAvailabilty.value) return;
    console.log("fetching claim");
    fetchClaim()
    const claimInterval = setInterval(fetchClaim, 3000);

    return () => clearInterval(claimInterval);
  }, [isConnectedToBSC.value, selectedVesting]);

  useEffect(() => {
    if(selectedVesting === "New") {
      if((claim as any)?.vestAmount != "0" ) {
        if(!isAlreadyAgreed) {
          console.log("calling")
          const account = metamask.user.primaryAccount;
          chumbiTermsAgreedCheck(account)
              .then((isAgreed) => {
                if(isAgreed) setIsAlreadyAgreed(true);
                if(!isAgreed) setShowTermsModal(true);
              })

        }
      }
    }

  }, [selectedVesting, claim]);


  useEffect(() => {
    setNetwork();
  }, []);

  // useEffect(() => {
  // 	if (isClaimAvailable.value) {
  // 		let isAmountAvailable = totalAmount - amountClaimed == 0;
  // 		if (isAmountAvailable) isAvailabilty.set(false);
  // 	}
  // }, [claim]);

  const claimChumbi = async () => {
    const contracts = Contracts.instances;
    const VestingContract = contracts[`Vesting_${selectedVesting}`];
    isTransactionInProgress.true();
    const isConnectedToPolygon = await metamask.checkIsBsc();
    if (!isConnectedToPolygon) {
      toast.error("Please connect to the Bsc Chain");
      isTransactionInProgress.false();
      return;
    }

    try {
      const callableNFT = VestingContract?.methods.claim();
      await callableNFT?.send({ from: metamask.user.primaryAccount });
      fetchClaim();
      toast.success("Claimed");
    } catch (err) {
      console.log(err);
      toast.error("Could not claim.");
    }
    isTransactionInProgress.false();
  };

  const totalAmount =
    (parseFloat(claim?.vestAmount) + parseFloat(claim?.unlockAmount)) / 1e18;
  const claimableAmount =
    (parseFloat(claim?.claimableAmount) + parseFloat(claim?.amountClaimed)) /
    1e18;
  const amountClaimed = parseFloat(claim?.amountClaimed) / 1e18;
  const claimableProgress =
    (parseFloat(claimableAmount.toString()) /
      parseFloat(totalAmount.toString())) *
    100;
  const claimedProgress =
    (parseFloat(amountClaimed.toString()) /
      parseFloat(claimableAmount.toString())) *
    100;

  let status = `${fromNow(claim.endTime)} Till Maturity`;
  if (
    Math.round(Date.now() / 1000) > parseFloat(claim.endTime) ||
    !claim.endTime
  )
    status = "Complete";
  if (claim?.paused) status = "Paused";
  const isDisabled =
    claim?.paused || totalAmount === amountClaimed || !claim?.isActive;


  const handleTermsModalClose = (_: any, reason: string) => {
    if (reason !== "backdropClick") {
      setShowTermsModal(false)
    }
  }

  return (
    <div className={classes.root}>
      <TermsModal open={showTermsModal} handleClose={handleTermsModalClose}  />
      <CssBaseline />
      <Navbar />
      <main className={classes.content}>
        <div className={classes.card}>
          <div className={classes.header}>
            <Text className={classes.title}>CLAIM TOKENS</Text>
            <select
              className={classes.select}
              defaultValue={selectedVesting}
              onChange={changeVesting}
            >
              <option disabled>Select Vesting</option>
              <option value="General">General Vesting</option>
              <option value="Seed">Seed Chumbi Airdrop</option>
              <option value="New">Private Investors</option>
            </select>
          </div>
          <table>
            <tbody>
              <tr className={classes.inputGroup}>
                <td className={classes.label}>Streamed</td>
                <td>
                  <ProgressBar
                    progress={claimableProgress ? claimableProgress : 0}
                    bgColor="#2E2D42"
                    fillColor={colors.btnPrimary}
                  />
                </td>
                <td>
                  <Text className={classes.desc}>
                    <span>{claimableAmount.toLocaleString()}</span> /{" "}
                    {totalAmount
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                    Total Vested
                  </Text>
                </td>
              </tr>
              <tr className={classes.inputGroup}>
                <td className={classes.label}>Claimed</td>
                <td>
                  <ProgressBar
                    progress={claimedProgress ? claimedProgress : 0}
                    bgColor="#2E2D42"
                    fillColor={colors.btnPrimary}
                  />
                </td>
                <td>
                  <Text className={classes.desc}>
                    <span>{amountClaimed.toLocaleString()}</span> /{" "}
                    {claimableAmount.toLocaleString()} Streamed
                  </Text>
                </td>
              </tr>
              <tr className={classes.inputGroup}>
                <td className={classes.label}>Time Left</td>
                <td colSpan={2}>
                  <Text className={classes.timeLeft}>{status}</Text>
                </td>
              </tr>
              <tr className={classes.inputGroup}>
                <td className={classes.label}>Availability</td>

                <td>
                  <Text className={classes.desc} style={{ marginLeft: "0" }}>
                    <span>
                      {(claimableAmount - amountClaimed).toLocaleString()}{" "}
                    </span>
                  </Text>
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            text="Claim $CHMB"
            className={[classes.button]}
            onClick={claimChumbi}
            loading={isTransactionInProgress.value}
            disabled={isDisabled}
            invert={isDisabled}
          />
        </div>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100%",
    },
    content: {
      display: "flex",
      flexGrow: 1,
      padding: theme.spacing(3),
      justifyContent: "center",
      height: "80%",
      alignItems: "center",
      color: "#fff",
      [theme.breakpoints.down(1000)]: {
        alignItems: "flex-start",
        marginTop: "100px",
      },
    },
    disabled: {
      backgroundColor: "grey !important",
    },
    card: {
      backgroundColor: colors.cardBg,
      marginLeft: 20,
      maWidth: "600px",
      height: "fit-content",
      borderRadius: "1.25rem",
      boxShadow: "0 0 35px 20px rgb(8 58 44 / 80%)",
      fontFamily: "Nunito",
      transition: "all .3s ease-in-out",
      boxSizing: "border-box",
      color: "white !important",
      padding: "2rem",
      [theme.breakpoints.down(1000)]: {
        marginLeft: 0,
      },

      [theme.breakpoints.down(800)]: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    inputGroup: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
      },

      [theme.breakpoints.down(800)]: {
        display: "flex",
        justifyContent: "center",

        flexDirection: "column",
      },
    },
    header: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

      [theme.breakpoints.down(800)]: {
        display: "flex",
        flexDirection: "column",
        marginBottom: "20px",
      },
    },
    select: {
      padding: "0.25rem 2rem",
      background: colors.bgPrimary,
      color: colors.textWhite,
      border: "none",
      borderRadius: "0.25rem",
      outline: "none",
      fontSize: "1.25rem",
      [theme.breakpoints.down(500)]: {
        width: "80%",
        fontSize: "16px",
        padding: "0.25rem",
      },
    },
    title: {
      fontFamily: font.primary,
      fontSize: font.fontSize.l,
      color: colors.white,
      letterSpacing: "0.05em",
      lineHeight: "72px",
      [theme.breakpoints.down(500)]: {
        fontSize: "25px",
      },
    },
    data: {
      display: "flex",
      flexDirection: "row",
      marginBottom: "30px",
    },
    label: {
      fontFamily: font.primary,
      fontSize: font.fontSize.sm,
      color: colors.white,
      lineHeight: "24px",
      textTransform: "uppercase",
      letterSpacing: "0.05em",
      padding: "17px 0px",

      [theme.breakpoints.down(800)]: {
        textAlign: "center",
        paddingRight: "0px",
      },
    },

    desc: {
      color: colors.white,
      fontSize: "16px",
      lineHeight: "24px",
      textTransform: "uppercase",
      letterSpacing: "0.35em",
      marginLeft: "20px",
      fontWeight: "bold",

      [theme.breakpoints.down(800)]: {
        textAlign: "center",
        marginLeft: "0px",
        marginTop: "10px",
      },
    },
    timeLeft: {
      color: colors.white,
      fontWeight: 600,
      lineHeight: "36px",
      letterSpacing: "0.5em",
      textTransform: "uppercase",
      [theme.breakpoints.down(800)]: {
        textAlign: "center",
      },
    },
    button: {
      border: "none",
      outline: "none",
      color: colors.textWhite,
      backgroundColor: colors.bgPrimary,
      fontFamily: font.primary,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "0.5rem 1rem",
      borderRadius: ".5rem",
      marginRight: "20px",
      cursor: "pointer",
      textDecoration: "none",
      fontSize: "20px",
      transition: "all 0.2s linear",
      "&:hover": {
        transform: "scale(1.1)",
      },

      [theme.breakpoints.down(800)]: {
        marginTop: "10px",
        marginRight: "0px",
      },
    },

    marginLeft: {
      marginLeft: "40px",
    },
  })
);
