// hooks
import { useMetamask } from '../../contexts/Metamask';
import useInput from '../../hooks/useInput';
import useSwitch from '../../hooks/useSwitch';

// libraries
import BN from 'bn.js';
import { toast } from 'react-toastify';
import { Typography as Text } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// components
import Button from '../Button';

// assets
import wallet from '../../assets/wallet.svg';

// configs
import font from '../../configs/font';
import colors from '../../configs/colors';

// helpers
import { stringToDate, toUnixTimestamp } from '../../utilities/utils';
import { validateAmount } from '../../utilities/validate';
import Contracts from 'helpers/contracts';
import { useContext } from 'react';
import { VestingContext } from './tab';
import { ContractAddress } from 'config';

export default function AddUser() {
	const classes = useStyles();
	const metamask = useMetamask();

	const walletAddress = useInput();
	const startDate = useInput();
	const endDate = useInput();
	const amount = useInput();
	const unlockAmount = useInput();
	const unlockTime = useInput();
	const isTransactionInProgress = useSwitch();
	const { selectedVesting } = useContext(VestingContext);

	const addUser = async () => {
		const contracts = Contracts.instances;
		const isConnectedToBSC = await metamask.checkIsBsc();
		if (!isConnectedToBSC) {
			toast.error('Please connect to the BSC Chain');
			isTransactionInProgress.false();
			return;
		}
		if (
			!walletAddress.value ||
			!startDate.value ||
			!endDate.value ||
			!amount.value
		) {
			return toast.error('Please fill all the fields');
		}
		if (!validateAmount(amount.value)) {
			return;
		}

		try {
			isTransactionInProgress.true();
			const UNIXStartDate = toUnixTimestamp(stringToDate(startDate.value));
			const UNIXEndDate = toUnixTimestamp(stringToDate(endDate.value));
			const UNIXUnlockDate = toUnixTimestamp(stringToDate(unlockTime.value));
			const _approveAmount = new BN(
				parseFloat(amount.value) + parseFloat(unlockAmount.value)
			).mul(new BN(10).pow(new BN(18)));
			const _vestAmount = new BN(parseFloat(amount.value)).mul(
				new BN(10).pow(new BN(18))
			);
			const _unlockAmount = new BN(unlockAmount.value).mul(
				new BN(10).pow(new BN(18))
			);
			const allowance = await contracts.Token?.methods
				.allowance(
					metamask.user.primaryAccount,
					ContractAddress[`Vesting_${selectedVesting}`]
				)
				.call();
			if (_approveAmount.gt(new BN(allowance || 0))) {
				await contracts.Token?.methods
					.approve(
						ContractAddress[`Vesting_${selectedVesting}`],
						_approveAmount.toString()
					)
					.send({ from: metamask.user.primaryAccount });
			}

			await contracts.Token?.methods
				.approve(
					ContractAddress[`Vesting_${selectedVesting}`],
					_approveAmount.toString()
				)
				.send({ from: metamask.user.primaryAccount });

			const callableNFT = contracts[
				`Vesting_${selectedVesting}`
			]?.methods.createClaim(
				walletAddress.value,
				_vestAmount.toString(),
				_unlockAmount.toString(),
				UNIXUnlockDate,
				UNIXStartDate,
				UNIXEndDate
			);

			await callableNFT?.send({ from: metamask.user.primaryAccount });
			toast.success('Claim Created');
		} catch (err) {
			console.log(err);
			toast.error('Could not create claim.');
		}
		isTransactionInProgress.false();
	};

	return (
		<div className={classes.block}>
			<div className={classes.flexDirectionColumn}>
				<table>
					<tbody>
						<tr className={classes.inputRow}>
							<td className={classes.label}>Wallet Address</td>
							<td>
								<input
									value={walletAddress.value}
									onChange={walletAddress.set}
									className={classes.input}
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
						<tr className={classes.inputRow}>
							<td className={classes.label}>Start Time</td>
							<td>
								<input
									value={startDate.value}
									onChange={startDate.set}
									className={classes.input}
									type='datetime-local'
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
						<tr className={classes.inputRow}>
							<td className={classes.label}>End Time</td>
							<td>
								<input
									value={endDate.value}
									onChange={endDate.set}
									className={classes.input}
									type='datetime-local'
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
						<tr className={classes.inputRow}>
							<td className={classes.label}>Amount $CHMB</td>
							<td>
								<input
									value={amount.value}
									onChange={amount.set}
									className={classes.input}
									type='number'
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
						<tr className={classes.inputRow}>
							<td className={classes.label}>Unlock Amount</td>
							<td>
								<input
									value={unlockAmount.value}
									onChange={unlockAmount.set}
									className={classes.input}
									type='number'
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
						<tr className={classes.inputRow}>
							<td className={classes.label}>Unlock Time</td>
							<td>
								<input
									value={unlockTime.value}
									onChange={unlockTime.set}
									className={classes.input}
									type='datetime-local'
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
					</tbody>
				</table>
				<Button
					text='Vest $CHMB'
					className={classes.button}
					onClick={addUser}
					loading={isTransactionInProgress.value}
				/>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		block: {
			height: '50%',
			display: 'flex',
			padding: '40px 40px',
			alignItems: 'center',
			justifyContent: 'flex-start',
			borderRadius: '16px',
		},
		image: {
			height: 60,
			marginLeft: 150,
		},
		title: {
			fontFamily: font.primary,
			fontSize: font.fontSize.l,
			color: colors.textSecondary,
			letterSpacing: '0.05em',
			lineHeight: '72px',
		},
		label: {
			fontFamily: font.primary,
			fontSize: font.fontSize.sm,
			lineHeight: '24px',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			padding: '17px 0px',
		},
		input: {
			fontFamily: font.primary,
			border: `1px solid  #e9d8ff`,
			color: colors.black,
			background: colors.white,
			borderRadius: '7px',
			padding: '0px 1rem',
			width: '380px',
			height: '44px',
			marginLeft: '40px',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				width: '80vw',
			},
			outline: 'none',
			'&:focus': {
				backgroundColor: '#fafafa',
				borderColor: '#8262d1',
			},
		},
		inputRow: {
			[theme.breakpoints.down('xs')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		},
		flexDirectionColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		button: {
			marginTop: '16px',
			fontSize: font.fontSize.sm,
			transition: 'all 0.15s linear',
			'&:hover': {
				transform: 'scale(1.1)',
			},
		},
	})
);
