import { ContractInstances, contracts } from 'config';
import Web3 from 'helpers/web3';

export default class Contracts {
	private static _instances: ContractInstances;

	public static get instances(): ContractInstances {
		if (Contracts._instances) return Contracts._instances;

		const web3 = Web3.instance;
		const ContractInstances: any = {};
		contracts.forEach((contract) => {
			ContractInstances[contract.name] = new web3.eth.Contract(
				contract.abi as any,
				contract.address
			);
		});
		Contracts._instances = ContractInstances;
		return Contracts._instances;
	}

	public static instanceWithDynamicAddress<T extends keyof ContractInstances>(
		name: T,
		address: string
	) {
		const web3 = Web3.instance;
		const contract = contracts.find((c) => c.name === name);
		if (contract) {
			const ContractInstance = new web3.eth.Contract(
				contract.abi as any,
				address
			) as unknown as ContractInstances[T];

			return ContractInstance;
		}
	}

	/**
	 * Manual Override
	 */
	public static set instances(value: ContractInstances) {
		Contracts._instances = value;
	}
}
