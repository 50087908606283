import Container, { Service } from 'typedi';
import Web3 from 'web3';
import { toast } from 'react-toastify';

@Service()
export class Web3Instance {
  private instance: Web3 | null = null;

  async getWeb3(): Promise<Web3> {
    if (this.instance === null) {
      try {
        await window.ethereum.enable();
        this.instance = new Web3(Web3.givenProvider || 'http://localhost:8545');
      } catch (error) {
        throw error;
      }
    }
    return this.instance;
  }
}

export async function getWeb3(): Promise<Web3> {
  return Container.get(Web3Instance).getWeb3();
}

export async function getAccounts() {
  const web3 = await getWeb3();
  return web3.eth.getAccounts();
}

export async function getBalance(address: string) {
  const web3 = await getWeb3();
  return web3.utils.fromWei(await web3.eth.getBalance(address));
}

export async function getNetworkId() {
  const web3 = await getWeb3();
  return web3.eth.net.getId();
}

export async function getAccountInformation() {
  let accounts: string[] = [];
  let balance = '0';
  try {
    accounts = await getAccounts();
    balance = await getBalance(accounts[0]);
  } catch (err: any) {
    console.error(err);
    if (err?.code === 4001) {
      toast.error('Please connect wallet to continue');
    }
  }
  return { accounts, balance };
}
