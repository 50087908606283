import { allowedChains } from 'config';
import { useEffect } from 'react';
import { useState, createContext, useContext } from 'react';
import { getNetworkId, getAccountInformation } from '../utilities/web3';

interface MetamaskContext {
	user: {
		accounts: string[];
		balance: string | undefined;
		primaryAccount: string;
	};
	connect: () => void;
	disconnect: () => void;
	checkIsBsc: () => Promise<boolean>;
}

interface MetamaskProviderProps {
	children: JSX.Element;
}

const checkIsBsc = async () => {
	const preferred_chain_id = allowedChains[0].id;
	const networkId = await getNetworkId();
	return networkId === preferred_chain_id;
};

const Metamask = createContext<MetamaskContext>({
	user: {
		accounts: [],
		balance: '0',
		primaryAccount: '',
	},
	connect: () => {},
	disconnect: () => {},
	checkIsBsc,
});

const MetamaskProvider = (props: MetamaskProviderProps) => {
	const [accounts, setAccounts] = useState<string[]>([]);
	const [balance, setBalance] = useState<string>();

	async function connect() {
		const data = await getAccountInformation();
		setAccounts(data?.accounts);
		setBalance(data?.balance);
	}

	const disconnect = () => {
		setAccounts([]);
		setBalance('');
	};

	useEffect(() => {
		const { ethereum } = window as any;
		ethereum.on('chainChanged', () => window.location.reload());
		ethereum.on('accountsChanged', () => window.location.reload());
	}, []);

	const wallet = {
		user: { accounts, primaryAccount: accounts[0], balance },
		connect,
		disconnect,
		checkIsBsc,
	};
	return <Metamask.Provider value={wallet}>{props.children}</Metamask.Provider>;
};

export const useMetamask = () => {
	return useContext(Metamask);
};

export default MetamaskProvider;
