import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Link from '@material-ui/core/Link';
import colors from "../configs/colors";
import font from "../configs/font";
import {chumbiTermsAgreedBase} from "../configs/airtable";

import {useMetamask} from "../contexts/Metamask";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#032422',
    boxShadow: 24,
    p: 4,
    color: 'white',
    fontFamily: "Nunito",
    borderRadius: "1.25rem",
    display: "flex",
    flexDirection: "column" as "column",
    gap: "1rem",
    alignItems: 'flex-start',
    maxWidth: "480px"
};

const buttonStyle = {
    border: "none",
        outline: "none",
        color: colors.textWhite,
        backgroundColor: colors.bgPrimary,
        fontFamily: font.primary,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "0.5rem 1.5rem",
        borderRadius: ".5rem",
        marginRight: "20px",
        cursor: "pointer",
        textDecoration: "none",
        fontSize: "15px",
        transition: "all 0.2s linear",
        fontWeight: 700,
        "&:hover": {
        transform: "scale(1.1)",
    },
};

type TermsModalProps = {
    open: boolean;
    handleClose: (event: any, reason: string) => void;
}

export default function TermsModal({open, handleClose}: TermsModalProps) {
    const {user} = useMetamask();
    const handleAgree = async () => {
        try {
            await chumbiTermsAgreedBase(user.accounts[0]);
            handleClose(null, 'agree');
        } catch (err: any) {
            console.log(err)
        }
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableEnforceFocus
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h4" component="h2" style={{fontWeight: 700}}>
                        New Vesting Terms
                    </Typography>
                    <Typography id="modal-modal-description" style={{color: '#EDF2F7'}}>
                        By claiming tokens from this portal you are agreeing to Option 1 of the
                        new CHMB private investor vesting terms (these are the same terms as
                        communicated previously). Please read the terms {" "}
                        <Link href="https://drive.google.com/file/d/1aH30ojY6_3n42G6X8mZdMYz9dQSd1UuE/view" style={{color: '#4299E1', textDecoration: 'underline', fontWeight: 700 }} >
                            here</Link> before claiming
                    </Typography>
                    <Button style={buttonStyle} variant="contained" onClick={handleAgree} >I Agree</Button>
                </Box>
            </Modal>
        </div>
    );
}
