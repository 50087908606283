const font = {
  primary: 'Nunito, sans-serif',
  bungeeRegular: 'Bungee-Regular',
  sairaRegular: 'Saira-Regular',
  sairaBold: 'Saira-Bold',
  sairaSemiBold: 'Saira-SemiBold',
  poppinsBoldItalic: 'Poppins-BoldItalic',
  poppinsSemiBoldItalic: 'Poppins-SemiBoldItalic',
  poppinsRegular: 'Poppins-Regular',
  poppinsExtraLightItalic: 'Poppins-ExtraLightItalic',
  poppinsItalic: 'Poppins-Italic',
  sourceSansProSemiBold: 'SourceSansPro-SemiBold',
  sourceSansProRegular: 'SourceSansPro-Regular',
  fontSize: {
    sm: '13px',
    m: '20px',
    l: '36px',
    xl: '40px',
    xxl: '48px'
  },
  fontWeight: {
    small: '200',
    regular: 400,
    bold: 500,
    extraBold: 900
  }
};

export default font;
