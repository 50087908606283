import { Container, Service } from 'typedi';
import { ChumbiVesting } from 'types/ChumbiVesting';
import { ChumbiToken } from 'types/ChumbiToken';

@Service()
export class ContractsInstance {
	private _ChumbiVesting: ChumbiVesting | undefined;
	private _ChumbiToken: ChumbiToken | undefined;
	public get ChumbiVesting(): ChumbiVesting {
		if (this._ChumbiVesting) return this._ChumbiVesting;
		else throw new Error('Token not initialized.');
	}
	public set ChumbiVesting(value: ChumbiVesting) {
		this._ChumbiVesting = value;
	}
	public get ChumbiToken(): ChumbiToken {
		if (this._ChumbiToken) return this._ChumbiToken;
		else throw new Error('Token not initialized.');
	}
	public set ChumbiToken(value: ChumbiToken) {
		this._ChumbiToken = value;
	}
}

export const getContract = () => {
	return Container.get(ContractsInstance);
};
