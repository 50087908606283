import { useState, useEffect } from "react";

// material-ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { Typography as Text } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

// configs
import font from "../configs/font";
import colors from "../configs/colors";

// assets
import metamaskLogo from "../assets/metamask.png";

export default function MetamaskNotAvailable() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main>
        <div className={classes.overlayDetails}>
          <img src={metamaskLogo} alt="Metamask Logo" height="129px" />
          <Text className={classes.alertDetail}>
            Please use a Metamask enabled browser.
          </Text>
        </div>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
      padding: "5%",
    },
    overlayDetails: {
      height: "100%",
      zIndex: 10,
      position: "absolute",
      top: 0,
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
    },
    alertDetail: {
      color: colors.white,
      fontFamily: "Nunito",
      fontSize: "48px",
      lineHeight: "72px",
      textTransform: "uppercase",
      letterSpacing: "0.15em",
      marginBottom: "30px",
      textAlign: "center",
    },
  })
);
