import { useState } from "react";

export default function useSwitch(initalState: boolean = false) {
  const [value, setValue] = useState<boolean>(initalState);
  const on = () => setValue(true);
  const off = () => setValue(false);
  const toggle = () => setValue((value) => !value);
  const set = (value: boolean) => setValue(value);
  return { value, true: on, false: off, toggle, set };
}
