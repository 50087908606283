import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface ProgressBarProps {
  bgColor: string;
  fillColor: string;
  progress: number;
}

export default function ProgressBar(props: ProgressBarProps) {
  const classes = useStyles();
  const progress = 350 - props.progress * 3.5;
  const colorStyle = {
    backgroundColor: props.fillColor,
    boxShadow: `inset -${progress}px 0px 0px ${props.bgColor}`
  };
  if (progress === 350) {
    colorStyle.boxShadow = `inset -${progress + 50}px 0px 0px ${props.bgColor}`;
  }
  return <div className={classes.bar} style={colorStyle} />;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      width: '360px',
      height: '28px',
      border: '1px solid #000000',
      borderRadius: '50px',
      transition: 'box-shadow 0.8s ease-in-out',
      [theme.breakpoints.down('sm')]: {
        width: '250px'
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    }
  })
);
