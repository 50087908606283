const colors = {
  borderPrimary: '#005766',
  btnPrimary: '#67A65E',
  bgPrimary: 'rgba(103, 166, 94, 1)',
  textWhite: '#ffffff',
  bgWhite: '#ffffff',
  textDarkgrey: '#8E8E8E',
  bgLightgreen: '#c2d9e6',
  textLightgreen: '#c2d9e6',
  textBlue: '#008eb0',
  bgPalegreen: '#e7faff',
  bgGrey: '#f6f6f6',
  bgDarkgreen: '#008eb0',
  borderDarkgreen: '#008eb0',
  borderGrey: '#272727',
  textDarkgreen: '#008eb0',
  borderCard: '#c4c4c4',
  bgTransparent: 'transparent',
  cardBg: '#032422',

  primary: '#121212',
  transparent: 'rgba(0,0,0,0)',
  inputBackground: '#FFFFFF',
  white: '#FFFFFF',
  textPrimary: '#E6947E',
  textSecondary: '#65656B',
  textTertiary: '#A7A7A7',
  success: '#28a745',
  error: '#dc3545',
  black: '#000',
  //darkgrey: '#8E8E8E',
  grey: '#2E2E2E',
  lightGrey: '#AEAEAE',
  border: '#272727'
};

export default colors;
