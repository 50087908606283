// hooks
import useSwitch from '../../hooks/useSwitch';
import useInput from '../../hooks/useInput';
import { useMetamask } from '../../contexts/Metamask';

// libraries
import clsx from 'clsx';
import { toast } from 'react-toastify';

// material-ui
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

// components
import Button from '../Button';

// configs
import font from '../../configs/font';
import colors from '../../configs/colors';

// helpers
import Contracts from 'helpers/contracts';
import { VestingContext } from './tab';
import { useContext } from 'react';

export default function Revoke() {
	const classes = useStyles();
	const metamask = useMetamask();
	const adminWalletAddress = useInput();
	const isTransactionInProgress = useSwitch();
	const { selectedVesting } = useContext(VestingContext);

	const addAdmin = async () => {
		const isConnectedToBSC = await metamask.checkIsBsc();
		if (!isConnectedToBSC) {
			toast.error('Please connect to the Bsc Chain');
			isTransactionInProgress.false();
			return;
		}
		if (!adminWalletAddress.value) {
			return toast.error('Please enter a wallet address');
		}
		const contracts = Contracts.instances;
		const VestingContract = contracts[`Vesting_${selectedVesting}`];
		isTransactionInProgress.true();
		try {
			await VestingContract.methods
				.revoke(adminWalletAddress.value)
				.send({ from: metamask.user.primaryAccount });
			adminWalletAddress.reset();
			toast.success('Claim Revoked');
		} catch (err) {
			toast.error('Could not revoke claim');
		}
		isTransactionInProgress.false();
	};

	return (
		<div className={classes.block}>
			<div className={classes.flexDirectionColumn}>
				<table>
					<tbody>
						<tr className={classes.inputRow}>
							<td className={`${classes.label}`}>Wallet Address</td>
							<td>
								<input
									onChange={adminWalletAddress.set}
									className={classes.input}
									disabled={isTransactionInProgress.value}
								/>
							</td>
						</tr>
					</tbody>
				</table>
				<Button
					className={classes.button}
					text='Revoke'
					onClick={addAdmin}
					loading={isTransactionInProgress.value}
				/>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		block: {
			height: '50%',
			padding: '40px 40px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			borderRadius: '16px',
		},
		image: {
			height: 60,
			marginLeft: 80,
		},
		inputRow: {
			[theme.breakpoints.down('xs')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		},
		title: {
			fontFamily: font.primary,
			fontSize: font.fontSize.l,
			color: colors.textSecondary,
			letterSpacing: '0.05em',
		},
		label: {
			fontFamily: font.primary,
			fontSize: font.fontSize.sm,
			lineHeight: '24px',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			padding: '17px 0px',
		},
		input: {
			fontFamily: font.primary,
			border: `1px solid #e9d8ff`,
			color: colors.black,
			background: colors.white,
			borderRadius: '7px',
			width: '380px',
			boxSizing: 'border-box',
			marginLeft: '30px',
			padding: '0.8rem 1rem',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
				width: '80vw',
			},
			outline: 'none',
			'&:focus': {
				backgroundColor: '#fafafa',
				borderColor: '#8262d1',
			},
		},
		isAdminButton: {
			fontFamily: font.primary,
			fontSize: '16px',
			lineHeight: '24px',
			width: '120px',
			height: '25px',
			borderRadius: 50,
			border: 'none',
			transition: 'background 250ms ease-in-out',
			textAlign: 'center',
			textTransform: 'uppercase',
			letterSpacing: '0.5em',
			marginLeft: '38px',
			backgroundColor: '#10C400',
			color: '#fff',
			cursor: 'pointer',
			[theme.breakpoints.down('xs')]: {
				marginLeft: '0px',
			},
		},
		green: {
			backgroundColor: '#4bb771',
		},
		red: {
			backgroundColor: '#d33853',
		},
		isAdminButtonText: {
			marginLeft: 7,
		},
		flexDirectionColumn: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
		},
		button: {
			marginTop: '16px',
			fontSize: font.fontSize.sm,
			transition: 'all 0.15s linear',
			'&:hover': {
				transform: 'scale(1.1)',
			},
		},
	})
);
