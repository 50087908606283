import React, { ChangeEvent, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

//components
import AddAdmin from "./AddAdmin";
import AddUser from "./AddUser";
import Withdraw from "./Withdraw";

//config
import font from "../../configs/font";
import colors from "../../configs/colors";

import clsx from "clsx";
import Revoke from "./Revoke";
import Pause from "./Pause";

const BLOCK = {
  ADD_ADMIN: "Add admin",
  ADD_USER: "Add user",
  PAUSE: "PAUSE",
  REVOKE: "REVOKE",
  WITHDRAW: "WITHDRAW",
};

interface tabItemProps {
  isActive: boolean;
  label: string;
  onClick: () => void;
}

type IVestingContext = {
  selectedVesting: "General" | "Seed" | "New";
};

export const VestingContext = React.createContext<IVestingContext>({
  selectedVesting: "General",
});

const Tab = () => {
  const classes = useStyles();
  const [stage, setStage] = useState(BLOCK.ADD_ADMIN);
  const navBarOptions: any = [
    {
      title: "Add Admin",
      onClick: () => setStage(BLOCK.ADD_ADMIN),
      selected: stage === BLOCK.ADD_ADMIN,
    },
    {
      title: "Vest $CHMB",
      onClick: () => setStage(BLOCK.ADD_USER),
      selected: stage === BLOCK.ADD_USER,
    },
    {
      title: "Pause",
      onClick: () => setStage(BLOCK.PAUSE),
      selected: stage === BLOCK.PAUSE,
    },
    {
      title: "Revoke",
      onClick: () => setStage(BLOCK.REVOKE),
      selected: stage === BLOCK.REVOKE,
    },
    {
      title: "Withdraw",
      onClick: () => setStage(BLOCK.WITHDRAW),
      selected: stage === BLOCK.WITHDRAW,
    },
  ];
  const [selectedVesting, setSelectedVesting] = useState<
    "General" | "Seed" | "New"
  >("General");

  const changeVesting = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedVesting(e.target.value as "General" | "Seed" | "New");
  };

  let cardContent = null;
  switch (stage) {
    case BLOCK.ADD_ADMIN:
      cardContent = <AddAdmin />;
      break;
    case BLOCK.ADD_USER:
      cardContent = <AddUser />;
      break;
    case BLOCK.PAUSE:
      cardContent = <Pause />;
      break;
    case BLOCK.REVOKE:
      cardContent = <Revoke />;
      break;
    case BLOCK.WITHDRAW:
      cardContent = <Withdraw />;
      break;
  }

  return (
    <div>
      <div className={classes.tab}>
        {navBarOptions.map((item: any, i: number) => (
          <TabItem
            key={i}
            label={item.title}
            onClick={item.onClick}
            isActive={item.selected}
          />
        ))}
      </div>
      <VestingContext.Provider value={{ selectedVesting }}>
        <div className={classes.card}>
          <select
            className={classes.select}
            defaultValue={selectedVesting}
            onChange={changeVesting}
          >
            <option disabled>Select Vesting</option>
            <option value="General">General Vesting</option>
            <option value="Seed">Seed Chumbi Airdrop</option>
            <option value="New">New vesting</option>
          </select>

          {cardContent}
        </div>
      </VestingContext.Provider>
    </div>
  );
};
const TabItem = ({ label, onClick, isActive }: tabItemProps) => {
  const classes = useStyles();
  return (
    <div
      className={`${classes.tabListItem} ${clsx({
        [classes.active]: isActive,
      })}`}
      onClick={onClick}
    >
      <p>{label}</p>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
      borderRadius: "20px",
      marginBottom: "40px",
      padding: "0 20px",
      backgroundColor: colors.cardBg,
      color: colors.black,
      boxShadow: "0 0 35px 20px rgb(8 58 44 / 80%)",
      [theme.breakpoints.down("sm")]: {},
    },
    select: {
      padding: "0.25rem 2rem",
      background: colors.bgPrimary,
      color: colors.textWhite,
      border: "none",
      borderRadius: "0.25rem",
      outline: "none",
      fontSize: "1.25rem",
      margin: "1rem 1rem 0 auto",
    },

    tabListItem: {
      fontSize: "20px",
      padding: "0px",
      marginRight: "20px",
      cursor: "pointer",
      width: "fit-content",
      fontFamily: "Nunito",
      color: colors.white,
      transition: "all 0.1s ease-in-out",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },

    card: {
      animation: "1s intoview",
      borderRadius: "1.25rem",
      backgroundColor: colors.cardBg,
      boxShadow: "0 0 35px 20px rgb(8 58 44 / 80%)",
      color: colors.white,
      display: "flex",
      flexDirection: "column",
    },

    active: {
      color: colors.btnPrimary,
      fontWeight: "bold",
    },

    "@keyframes intoview ": {
      from: {
        transform: "translateY(40px)",
      },
      to: {
        transform: "translateY(0px)",
      },
    },
  })
);

export default Tab;
