import 'reflect-metadata';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import MetamaskProvider from './contexts/Metamask';
import ContractProvider from './contexts/Contract';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({ theme: 'colored', className: 'toastContainer' });

ReactDOM.render(
  <React.StrictMode>
    <MetamaskProvider>
      <ContractProvider>
        <Router>
          <App />
        </Router>
      </ContractProvider>
    </MetamaskProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
